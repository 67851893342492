import * as React from "react";
import { navigate } from "gatsby";
import ClearIcon from "../icons/clear-icon.svg";
import Image from "../image/image";
import {
  pushActionableClickEvent,
  EventCategory,
} from "../../../utils/analytics";
import { QuestionButton } from "../question-link/question-link";
import styles from "./answer-flag.styles";
import AnswerFlagIcon from "./answer-flag.svg";

interface AnswerFlagProps {
  answerType: "text" | "image" | "video";
  intentName: string;
  answerIndex: number | string;
}

const AnswerFlag: React.FC<AnswerFlagProps> = ({
  answerType,
  intentName,
  answerIndex,
}) => {
  const flagRef = React.useRef<HTMLDivElement>(null);

  const [isPopoverOpen, setPopoverOpen] = React.useState<boolean>();
  const [isFlagged, setFlagged] = React.useState<boolean>();

  const eventLabel = `${intentName} - ${answerType} - ${answerIndex}`;

  React.useEffect(() => {
    const listener = (event: MouseEvent) => {
      if (
        event.target &&
        flagRef.current &&
        !flagRef.current.contains(event.target as Node)
      ) {
        setPopoverOpen(false);
      }
    };
    if (isPopoverOpen && window && flagRef.current) {
      document.addEventListener("click", listener);
    }

    if (!isPopoverOpen && window) {
      document.removeEventListener("click", listener);
    }

    return () => {
      document.removeEventListener("click", listener);
    };
  }, [isPopoverOpen]);

  React.useEffect(() => {
    let dismissTimeOut: NodeJS.Timeout;
    if (isFlagged) {
      dismissTimeOut = setTimeout(() => {
        setPopoverOpen(false);
      }, 2500);
    }

    return () => {
      clearTimeout(dismissTimeOut);
    };
  }, [isFlagged, isPopoverOpen]);

  const handleFlagClick = () => {
    setPopoverOpen(true);
    pushActionableClickEvent({
      category: EventCategory.AnswerFlag,
      action: "Click - Flag answer",
      label: eventLabel,
    });
  };

  const handleOutdatedClick = () => {
    setFlagged(true);
    pushActionableClickEvent({
      category: EventCategory.AnswerFlag,
      action: "Click - Outdated",
      label: eventLabel,
    });
  };

  const handleUntrustWorthyClick = () => {
    setFlagged(true);
    pushActionableClickEvent({
      category: EventCategory.AnswerFlag,
      action: "Click - Untrustworthy",
      label: eventLabel,
    });
  };

  const handleBetterSuggestionClick = () => {
    setFlagged(true);
    pushActionableClickEvent({
      category: EventCategory.AnswerFlag,
      action: "Click - Better suggestion",
      label: eventLabel,
    });
    navigate("/suggest-question-and-answer/");
  };

  const handleDismiss = () => setPopoverOpen(false);

  return (
    <>
      {isPopoverOpen && <div css={styles.backdrop} />}

      <div css={styles.buttonContainer}>
        <button
          css={[styles.button, isPopoverOpen && styles.buttonActive]}
          onClick={handleFlagClick}
        >
          <Image src={AnswerFlagIcon} alt="Flag" />
        </button>
      </div>

      {isPopoverOpen && (
        <div ref={flagRef} css={styles.optionContainer}>
          <div css={{ display: isFlagged ? "none" : "block" }}>
            <div css={styles.heading}>
              Is there a problem with this content?
            </div>

            <QuestionButton
              css={styles.optionButton}
              onClick={handleOutdatedClick}
              eventCategory={EventCategory.AnswerFlag}
            >
              It’s outdated. You should update it
            </QuestionButton>
            <QuestionButton
              css={styles.optionButton}
              onClick={handleUntrustWorthyClick}
              eventCategory={EventCategory.AnswerFlag}
            >
              It’s untrustworthy. I don’t trust it
            </QuestionButton>
            <QuestionButton
              css={styles.optionButton}
              onClick={handleBetterSuggestionClick}
              eventCategory={EventCategory.AnswerFlag}
            >
              I have a better suggestion I’d like to share
            </QuestionButton>
          </div>

          <div
            css={{
              display: isFlagged ? "block" : "none",
              maxWidth: "calc(100% - 30px)",
            }}
          >
            <strong>Thanks for helping us improve our answer</strong>
          </div>

          <div onClick={handleDismiss} css={styles.clearButton}>
            <Image src={ClearIcon} alt="close" />
          </div>
        </div>
      )}
    </>
  );
};

export default AnswerFlag;
