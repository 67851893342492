import * as React from "react";

import { PageProps, graphql } from "gatsby";
import Layout from "../../components/layout";
import SEO from "../../components/seo/seo";
import PageContainer from "../../components/ui-library/page-container/page-container";
import { LogoBunny } from "../../components/site/header/header";
import PageHeading from "../../components/ui-library/page-heading/page-heading";
import Container from "../../components/ui-library/container/container";
import DailyCaseByCountry from "../../components/covid-19/covid-intent/intent-specific-answers/daily-case-by-country/daily-case-by-country";
import SuggestedIntent from "../../components/common-page-sections/suggested-intent/suggested-intent";
import CollectQAndA from "../../components/common-page-sections/collect-q-and-a/collect-q-and-a";
import Feedback from "../../components/feedback/feedback";
import SocialShare from "../../components/ui-library/social-share/social-share";
import { getCountryUrl } from "../../utils/question-url";
import Navigation from "../../components/site/navigation/navigation";
import { TOP_NAV_ITEM } from "../../constants/top-navigations";
import { Topic } from "../../types/topics";
import { Covid19AskCountriesQueryQuery } from "../../../graphql-types";
import ECVBanner from "../../components/covid-19/third-party-banner/ecv-banner";

interface IntentData {
  countryName: string;
  country: string;
  alias: string;
}

type Covid19AskCountriesProps = PageProps<
  Covid19AskCountriesQueryQuery,
  IntentData
>;

const Covid19AskCountries: React.FC<Covid19AskCountriesProps> = ({
  pageContext: { countryName, country, alias },
  data,
}) => {
  const intentName = `What is the COVID-19 situation in ${countryName}?`;

  return (
    <Layout
      topic={Topic.COVID19}
      description="coronavirus"
      headerUrl="/covid-19/"
      logoBunny={LogoBunny.FaceMask}
    >
      <SEO
        title={intentName}
        description={`Latest COVID-19 confirmed cases, death, and case fatality rate statistics in ${countryName}.`}
        imageUrl={`/media/images/social-share/covid-19/country-${alias}.jpg`}
      />

      <Navigation topic={Topic.COVID19} activeItem={TOP_NAV_ITEM.Insights} />

      <PageContainer>
        <PageHeading>{intentName}</PageHeading>

        <Container fullWidthOnMobile={true}>
          <DailyCaseByCountry
            countryName={countryName}
            country={country}
            intentName={intentName}
            prefetchedCovidData={data.askAboutQuery.covidCaseOwidByCountry}
          />

          <Feedback intentName={intentName} topic={Topic.COVID19} />
        </Container>

        <Container>
          <SuggestedIntent
            suggestedIntent={{
              alias: `what-is-the-government-response-timeline-for-${alias}`,
              displayName: `What's the government response timeline for ${countryName}?`,
            }}
            topic={Topic.COVID19}
          />

          <SocialShare
            title={intentName}
            description={`Latest COVID-19 confirmed cases, death, and case fatality rate statistics in ${countryName}.`}
            url={getCountryUrl(alias)}
          />

          <ECVBanner />

          <CollectQAndA />
        </Container>
      </PageContainer>
    </Layout>
  );
};

export default Covid19AskCountries;

export const query = graphql`
  query Covid19AskCountriesQuery($country: String!) {
    askAboutQuery {
      covidCaseOwidByCountry(country: $country) {
        latest {
          date
          newCases
          newDeaths
          newRecovered
          newActive
          newTests
          totalCases
          totalCasesPerMillion
          totalDeaths
          totalDeathsPerMillion
          totalTests
          totalTestsPerThousand
          totalRecovered
          totalRecoveredPerMillion
          totalActive
          totalActivePerMillion
          averageNewCases
          averageNewCasesPerMillion
          averageNewDeath
          averageNewDeathPerMillion
          averageNewTests
          averageNewTestsPerThousand
          averageNewRecovered
          averageNewRecoveredPerMillion
          fatalityRate
          fatalityRateChange
          testsUnits
        }
        data {
          date
          totalCases
          newCases
          averageNewCases
          totalDeaths
          newDeaths
          totalTests
          totalRecovered
          totalActive
          infectionRate
          predictedNewDeath
          fatalityRate
        }
      }
    }
  }
`;
