import { Interpolation } from "@emotion/core";
import { transparentize } from "polished";
import { Device, DarkTheme } from "../../design-tokens/media-queries";
import { grid } from "../../design-tokens/grid";
import { Border } from "../../design-tokens/borders";
import { Colors } from "../../design-tokens/colors";
import { Fonts } from "../../design-tokens/fonts";
import { Keyframes } from "../../design-tokens/animation";

const backdrop: Interpolation = {
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: transparentize(0.4, Colors.White),
  zIndex: 1,

  [DarkTheme]: {
    backgroundColor: transparentize(0.2, Colors.DarkModeBackground),
  },
};

const buttonContainer: Interpolation = {
  position: "absolute",
  right: grid(1),
  bottom: grid(4),
  zIndex: 2,

  [Device.DesktopTablet]: {
    right: grid(4),
  },
};

const button: Interpolation = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flex: 1,
  WebkitAppearance: "none",
  width: grid(5),
  height: grid(4),
  borderRadius: Border.RadiusSm,
  border: 0,
  padding: 0,
  backgroundColor: "transparent",
  transition: "all 0.2s ease",
  cursor: "pointer",

  ":hover": {
    outline: 0,
  },

  ":active": {
    outline: 0,
    transform: "scale(1.05)",
  },

  ":focus": {
    outline: 0,
  },
};

const buttonActive: Interpolation = {
  backgroundColor: transparentize(0.95, Colors.Blue),

  [DarkTheme]: {
    backgroundColor: transparentize(0.5, Colors.DarkModeBlack),
  },
};

const optionContainer: Interpolation = {
  position: "absolute",
  right: grid(2),
  bottom: grid(9),
  width: grid(33.5),
  maxWidth: "calc(100% - 40px)",
  padding: grid(2),
  borderRadius: Border.Radius,
  backgroundColor: Colors.White,
  boxShadow: "0px 5px 20px rgba(0, 0, 0, 0.15)",
  zIndex: 3,
  animation: `${Keyframes.FadeIn} 0.3s linear`,

  [DarkTheme]: {
    backgroundColor: Colors.DarkModeBlack,
  },

  "::after": {
    content: `""`,
    display: "block",
    position: "absolute",
    bottom: "-8px",
    right: "16px",
    width: grid(2),
    height: grid(2),
    borderRadius: 3,
    backgroundColor: Colors.White,
    transform: "rotate(45deg)",

    [DarkTheme]: {
      backgroundColor: Colors.DarkModeBlack,
    },
  },

  [Device.DesktopTablet]: {
    right: grid(6),
  },
};

const heading: Interpolation = {
  fontWeight: Fonts.Weight.Bold,
  marginBottom: grid(2),
  maxWidth: "calc(100% - 30px)",
};

const optionButton: Interpolation = {
  width: "100%",
  minHeight: grid(4.5),
  fontSize: "14px",
  backgroundColor: transparentize(0.95, Colors.Blue),

  ":not(:last-child)": {
    marginBottom: grid(1.5),
  },

  [DarkTheme]: {
    backgroundColor: transparentize(0.5, Colors.DarkModeBackground),
  },

  [Device.MobilePortXs]: {
    fontSize: "13px",
  },
};

const clearButton: Interpolation = {
  position: "absolute",
  top: grid(1.5),
  right: grid(1.5),
  padding: grid(0.5),
  cursor: "pointer",
};

const styles = {
  backdrop,
  buttonContainer,
  button,
  buttonActive,
  optionContainer,
  heading,
  optionButton,
  clearButton,
};

export default styles;
